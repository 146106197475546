body{
	padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
}

.grad-bg{
	//background: url('/assets/img/background-images/holding-page_01.jpg') no-repeat;
	background-size: contain;
	background-position: 50% 50%;
	background-color: #000;
	width:100%;
	display:flex;
	justify-content:center;
	align-items:center;

}

.pwf-logo-video{
  display: block; 
  margin: 10% auto auto auto; 
  width:100%; 
  max-width: 300px;
  @media screen and (max-width: 63.9375em) {
    display:none;
  }
}

.carbon-background{
	background:
	radial-gradient(black 15%, transparent 16%) 0 0,
	radial-gradient(black 15%, transparent 16%) 8px 8px,
	radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
	radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
	background-color:#282828;
	background-size:16px 16px;
}

.rally-button{
	margin: 20px auto 20px auto; 
	display: block; 
	background: red; 
	color: #FFF; 
	padding-top: 6px; 
	padding-bottom: 8px; 
	text-transform: uppercase;
  border: 2px solid white;
	&:hover{
		background: #000;
		color: #FFF; 
	}
}

.hover-button{
  min-height: 200px;
  
  img,input{
    margin:auto; 
    width: 200px;
  }
  .off{
    display: block;
  }
  .on{

    display: none;
  }
  &:hover{
    .on{
      display: block;
    }
    .off{
      display: none;
    }
  }
}

.index-section{
  padding-top: 150px; padding-bottom: 150px;
  @media screen and (max-width: 63.9375em) {
      padding-top: 100px; padding-bottom: 100px;
    }
  @media screen and (max-width: 39.9375em) {
      padding-top: 50px; padding-bottom: 50px;
    }
}

.parallax {
    /* The image used */
    

    /* Set a specific height */
    height: 500px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @supports (-webkit-overflow-scrolling: touch) {
      background-attachment: scroll;
    }
    @media screen and (max-width: 63.9375em) {
      height: 400px;
      background-attachment: scroll;
    }
    @media screen and (max-width: 39.9375em) {
      height: 300px;
    }
}

.center_vertically {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* ---- grid ---- */

.grid {
  margin-bottom: 20px;
}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.grid-sizer,
.grid-item {
  width: 33.333%;
  @media screen and (max-width: 63.9375em) {
    width: 50%;
  }
  @media screen and (max-width: 39.9375em) {
    width: 100%;
  }
}

.grid-item {
  float: left;
}

.grid-item--width2 { 
  width: 100%; 
}

.grid-item--width3 { 
  width: 66.666%; 
  @media screen and (max-width: 63.9375em) {
    width: 50%;
  }
  @media screen and (max-width: 39.9375em) {
    width: 100%;
  }
}

.grid-item img {
  display: block;
  max-width: 100%;
}