.nav-bar{
	background-color: #000000;
	box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
	width:100%;
	z-index:1;
}

.logo-container{
	position: absolute;
	top: 5px;
	left: 15px;
}
.large-logo{
	position: absolute;
	width: 200px;
	top: 10px;
	z-index: 2;
	@media screen and (max-width: 63.9375em) {
		display: none;
	}
}

.pwf-roundel{
  display: inline-block; 
  width: 160px; 
  margin-left: 5px; 
  margin-top: 5px;
  @media screen and (max-width: 1300px) {
    width: 120px; 
  }
  @media screen and (max-width: 63.9375em) {
    width: 100px; 
  }
}

.title-bar{
	background-color: #000000;

  width: 100%;
	
	.menu-button-container{
		width: 100%;
    .menu-icon{
      height: 20px;
    }
		&:hover{
			cursor: pointer;
		}
	}
}

.top-bar{
  width: 100%;
	background-color: #000000;
	position: relative;
	z-index: 1;
	padding: 0;
	font-weight: 600;
	font-size: 16px;
	ul{
		background-color: #000000;
		margin: 0 auto;
    display: flex;
    justify-content: center;
    border: 0;
		li{
      ul {
        background-color: #666666;
        left: 0 !important;
        right: 0 !important;
        width: auto !important;
      }
      ul{
        
        li{
          a{
          }
        }
      }
			a{
				text-align: center;
				text-transform: uppercase;
				color: #FFF !important;
				min-width: 100%;
        font-family: "karmina", serif;
				&:hover{
					color: darkgrey !important;
				}
				
				text-align: left;
				
			}
		}
	}
	
}

li.mega-menu {
  .dropdown-pane {
    width: 100% !important;
    max-width: 5000px !important;
    margin: 0 auto !important;
    left: 0% !important;
    right: 0% !important;
    border:0;
    padding: 0 16px 0 16px;
    background-color: #666666;
  }

  .menu {
    background: none;
  }
}

