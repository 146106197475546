.item-title{
	color: #333333;
}

.item-description{
	color: #333333;

	.button{
		color: #FFF;
	}
}

.thumbnails {
	overflow: hidden;
	margin: 0;
	list-style: none;

	li {
		float: left;
		width: 20%;
		border-top: 5px solid #FFF;
		border-right: 5px solid #FFF;
		&:hover{
			opacity: 0.8;
		}

		a{
			float: left;
			img {
				display: block;
				min-width: 100%;
				max-width: 100%;
			}
		}
	}
}


.shopping-cart{
	transition: all .2s ease-in;
	background-color: rgba(102,102,102, 0.3);
	p {
		color: #FFF; 
		font-weight: 600; 
		padding-top: 5px; 
		padding-bottom: 5px; 
		margin: 0;
	}
	&:hover{
		cursor: pointer;
		transition: all .2s ease-in;
	background-color: rgba(102,102,102, 0.7);
	}
}

.index-thumb-container{
	h1, h2{
		color: #333333;
	}
	&:hover{
		opacity: 0.8;
	}
}



.store-breadcrumb{
	padding-top: 10px; 
	padding-bottom: 10px; 
	margin: 0; 
	color: #808080;
	a {
		color: #808080;
		&:hover{
			color: #666666;
			text-decoration: underline;
		}
	}
}

.tabs-title{
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	background-color: grey;
}

.accordion-item{
	background-color: #FFF;
	border-top: 1px #808080 solid;
	a {
		color: #999999;
		text-transform: uppercase;
		&:hover{
			color: inherit;
		}
	}
}

.is-active{
}

.colour-block{
	&:hover{
		cursor: pointer;
	}
}

.selectbox-left {

	@media screen and (min-width: 64em) {
		padding-right: 8px;	
	}
	
}

.selectbox-right {
	@media screen and (min-width: 64em) {
		padding-left: 8px;	
	}
}