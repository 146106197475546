h1.headerscript{
  font-family: "emily-austin", serif;
  color: #FFF;
  font-size: 150pt;
  line-height: 36pt;
  margin-top: 250px; margin-bottom:250px;
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    margin-top: 150px; margin-bottom:150px;
    font-size: 100pt;
  }
  @media screen and (max-width: 39.9375em) {
    margin-top: 100px; margin-bottom:100px;
    font-size: 65pt;
  }

}

h1.headerscript-home{
  font-family: "emily-austin", serif;
  color: #FFF;
  font-size: 150pt;
  line-height: 36pt;
  margin-top: 5%;
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    margin-top: 20%;
    font-size: 100pt;
  }
  @media screen and (max-width: 39.9375em) {
    margin-top: 15%;
    font-size: 65pt;
  }

}

h2, h3, h4, h5{
  font-family: "karmina", serif; 
  font-weight: 700;
}

p{

  font-family: "acumin-pro", sans-serif; 
}